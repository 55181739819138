import type { FamilyMemberTenantDto } from '@pflegenavi/shared/api';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import { get, getApiBaseUrl } from '@pflegenavi/shared-frontend/platform';

export interface IFamilyMemberApi extends Api {
  getFamilyMemberTenant(): Promise<FamilyMemberTenantDto[]>;
}

export class FamilyMemberApi implements IFamilyMemberApi {
  public getFamilyMemberTenant: IFamilyMemberApi['getFamilyMemberTenant'];

  constructor(public authContext: AuthenticationContext, apiUrl?: string) {
    const familyBaseUrl = getApiBaseUrl('family_member', apiUrl);

    this.getFamilyMemberTenant = get({
      authContext,
      url: `${familyBaseUrl}/familyMemberTenant`,
    });
  }
}
