import {
  IsArray,
  IsBoolean,
  IsEmail,
  IsLocale,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Matches,
  ValidateNested,
} from 'class-validator';
import { DirectDebitStatus } from '../payment';
import { PHONE_NUMBER_REGEX } from '@pflegenavi/shared/constants';
import { Gender } from './Gender';

export class FamilyMemberDto {
  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;

  @IsNotEmpty()
  @IsEmail()
  eMail!: string;

  @IsOptional()
  @Matches(PHONE_NUMBER_REGEX)
  phone?: string;

  @IsOptional()
  @IsString()
  address?: string;

  @IsOptional()
  @IsString()
  country?: string;

  @IsOptional()
  @IsString()
  city?: string;

  @IsOptional()
  @IsNumber()
  zipCode?: number;

  @IsOptional()
  @IsLocale()
  locale?: string;

  @IsOptional()
  @IsString()
  notes?: string;
}

export interface FamilyMemberPhoenix {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  address?: string;
  country?: string;
  city?: string;
  zip_code?: number;
  locale?: string;
  notes?: string;
}

export class FamilyMemberWithIdDto extends FamilyMemberDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsBoolean()
  emailVerified!: boolean;

  @IsOptional()
  directDebit?: DirectDebitStatus;

  @IsOptional()
  @IsBoolean()
  primaryContact?: boolean;

  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  tenants!: FamilyMemberTenantDto[];
}

export class FamilyMemberTenantDto {
  @IsNotEmpty()
  @IsString()
  tenant!: string;

  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  residentId!: string;

  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;

  @IsNotEmpty()
  gender!: Gender;

  @IsNotEmpty()
  @IsString()
  nursingHomeId!: string;

  @IsNotEmpty()
  @IsString()
  nursingHomeName!: string;
}

/**
 * Contains only fields that do not require Keycloak
 */
export type FamilyMemberDatabaseDto = Omit<
  Omit<FamilyMemberDto, 'locale'>,
  'eMail'
> & {
  id: string;
  primaryContact?: boolean;
  emailVerified?: boolean;
};
