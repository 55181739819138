import type {
  UploadFile,
  UploadState,
} from '@pflegenavi/shared-frontend/platform';
import {
  makeApiProvider,
  useApiInfiniteQuery,
  useApiQuery,
  usePrefetchApiQuery,
  useUploadFile,
} from '@pflegenavi/shared-frontend/platform';
import type {
  QueryKey,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';

import {
  invalidatePendingReceiptCount,
  invalidateResident,
  RESIDENTS_KEY,
  usePrefetchResidents,
} from '../resident';
import type {
  CancelManualDepositDto,
  CancelReceiptDto,
  CreateOrUpdateReceiptBatchEntryDto,
  CreateReceiptBatchJobAction,
  CreateReceiptBatchWithNursingHome,
  CreateReceiptDto,
  FilteredTransactionChangesResultDto,
  GetFilteredBalanceChangesQueryParams,
  GetReceiptBatchesQueryDto,
  GetTotalResidentBalanceQueryParams,
  GetTransactionsPaginatedQueryParams,
  GetTransactionsWithReceiptDto,
  IReceiptType,
  ManualDepositDto,
  PaginatedReceiptQueryDto,
  PaginatedReceiptResult,
  PatchReceiptBatchDto,
  ReceiptBatchGetDto,
  ReceiptBatchJob,
  ReceiptBatchListDto,
  ReceiptBatchPaginatedDto,
  ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
  ReceiptFull,
  ReceiptsAndReceiptBatchesDto,
  ReceiptsAndReceiptBatchesQueryParams,
  TotalResidentBalanceResultDto,
  TransactionSourceType,
  UpdateReceiptBatchCashTransactionGroupDto,
  UpdateReceiptBatchResultDto,
  UpdateReceiptBatchWithNursingHome,
  UpdateTransactionDto,
} from '@pflegenavi/shared/api';
import { ReceiptStatusValues } from '@pflegenavi/shared/api';
import { createContext, useCallback, useMemo } from 'react';
import type { ITransactionApi } from './api';
import { TransactionApi } from './api';
import isEqual from 'lodash.isequal';
import type {
  PaginatedResultSet,
  PaginatedResultSubset,
} from '@pflegenavi/shared/utils';
import {
  CASH_LIST_CONFIGURATION_KEY,
  CASH_TRANSACTION_GROUP_KEY,
} from '../cash-management';
import { usePrefetchServiceProviders } from '../service-provider';
import { invalidateAllResidentBalance } from '../reporting';
import { RECEIPT_BATCH_PAYMENT_STATUS } from '../payment';

export {
  isReceiptWithInactiveServiceProviderError,
  isReceiptBatchWithInactiveServiceProviderError,
} from './api';

const ApiContext = createContext<ITransactionApi | undefined>(undefined);
const { useApi: useTransactionApi, ApiProvider: TransactionApiProvider } =
  makeApiProvider({
    name: 'Transaction',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new TransactionApi(tenantId, auth, apiUrl),
  });

export type { TransactionApi, ITransactionApi };
export {
  useTransactionApi,
  TransactionApiProvider,
  ApiContext as TransactionApiContext,
};

const RECEIPTS_KEY = 'receipts';
export const getReceiptKey = (
  id: string,
  params?: Record<string, any>
): QueryKey => [
  RECEIPTS_KEY,
  id,
  {
    ...params,
  },
];
const RECEIPTS_KEY_NURSING_HOME = (
  nursingHomeId: string | undefined,
  params?: Record<string, any>
) => [RECEIPTS_KEY, nursingHomeId, params ?? {}];
const TRANSACTION_TYPES_KEY = 'receiptTypes';

const CREATE_RECEIPT_KEY = 'create-transaction';
const UPDATE_TRANSACTION_KEY = 'update-transaction';
const DELETE_TRANSACTION_KEY = 'delete-transaction';
const CANCEL_RECEIPT_KEY = (receiptId: string) => `cancel-receipt-${receiptId}`;
const SUBMIT_RECEIPTS_KEY = 'submit-receipts';
const MANUAL_DEPOSIT_KEY = 'manual-deposit';
const MANUAL_DEPOSIT_CANCELLATION_KEY = 'manual-deposit-cancellation';
const getReceiptImagesKey = (id?: string) => `receipt-images-multi-${id}`;

export const CREATE_RECEIPT_BATCH = 'create-receipt-batch';
export const CREATE_RECEIPT_BATCH_ENTRY = 'create-receipt-batch-entry';
export const RECEIPT_BATCH_LIST_KEY = (
  nursingHomeId: string | undefined
): QueryKey => ['receipt-batch_list', nursingHomeId];
export const RECEIPT_BATCH_KEY = (batchId?: string): QueryKey => [
  'receipt-batch',
  batchId,
];
export const UPDATE_RECEIPT_BATCH = 'update-receipt-batch';
export const UPDATE_RECEIPT_BATCH_ENTRY = 'update-receipt-batch-entry';
export const DELETE_RECEIPT_BATCH = 'delete-receipt-batch';
export const CREATE_RECEIPT_BATCH_JOB = 'create-receipt-batch-job';
export const RECEIPT_BATCH_LIST_PAGINATED_KEY = (
  opts: Partial<GetReceiptBatchesQueryDto> & {
    nursingHomeId: string | undefined;
  }
): QueryKey => [
  'receipt-batch_list-paginated',
  opts?.nursingHomeId,
  {
    ...opts,
  },
];

const RECEIPTS_AND_RECEIPT_BATCHES_PREFIX = 'receipts-and-receipt-batches';
export const RECEIPTS_AND_RECEIPT_BATCHES_KEY = (
  nursingHomeId: string | undefined,
  params?: Omit<ReceiptsAndReceiptBatchesQueryParams, 'page'>
): QueryKey => [
  RECEIPTS_AND_RECEIPT_BATCHES_PREFIX,
  nursingHomeId,
  params ?? {},
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TRANSACTIONS_PAGINATED_KEY = (
  opts: Partial<
    GetTransactionsPaginatedQueryParams & {
      nursingHomeId: string | undefined;
    }
  >
) => [
  opts.nursingHomeId,
  {
    ...opts,
  },
];

const TOTAL_BALANCE_PERIOD_KEY = (
  opts: GetTotalResidentBalanceQueryParams & {
    nursingHomeId: string | undefined;
  }
) => [
  'total-balance-period',
  opts.nursingHomeId,
  {
    ...opts,
  },
];

const FILTERED_BALANCE_CHANGES_PERIOD_KEY = (
  opts: GetFilteredBalanceChangesQueryParams & {
    nursingHomeId: string | undefined;
  }
) => [
  'filtered-balance-changes-period',
  opts.nursingHomeId,
  {
    ...opts,
  },
];

const RECEIPT_BATCH_JOB_KEY = (batchJobId?: string) => [
  'receipt-batch-job',
  batchJobId,
];
const RESIDENT_BALANCE_KEY = (residentId?: string, date?: Date) => {
  return ['resident-balance', residentId, date];
};

export const getTransactionsKey = (
  residentId: string,
  transactionTypes: string[] = []
): string => `transactions-${residentId}-${transactionTypes.join('-')}`;

export const getTransactionsAsFamilyMemberKey = (
  residentId: string,
  transactionTypes: string[] = []
): string =>
  `transactions-family-member-${residentId}-${transactionTypes.join('-')}`;

export const useReceipts = (
  nursingHomeId: string | undefined
): UseQueryResult<ReceiptFull[]> => {
  return useApiQuery(
    useTransactionApi,
    RECEIPTS_KEY_NURSING_HOME(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
      refetchOnWindowFocus: true,
    }
  );
};

export function useReceiptsPaginated<TData = PaginatedReceiptResult>(
  params: Omit<PaginatedReceiptQueryDto, 'nursingHomeId'> & {
    nursingHomeId?: string;
  },
  options?: UseQueryOptions<PaginatedReceiptResult, unknown, TData>
): UseQueryResult<TData> {
  return useApiQuery(
    useTransactionApi,
    RECEIPTS_KEY_NURSING_HOME(params.nursingHomeId, params),
    (api) => {
      const { nursingHomeId, ...otherParams } = params;
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getPaginated({
        params: {
          ...otherParams,
          nursingHomeId,
        },
      });
    },
    {
      ...options,
      enabled: (options?.enabled ?? true) && params.nursingHomeId !== undefined,
      refetchOnWindowFocus: true,
    }
  );
}

export const usePrefetchFirstOpenReceipts = (
  nursingHomeId: string | undefined
): void => {
  const params = useMemo(
    () => ({
      nursingHomeId,
      pageSize: 50,
      page: 0,
      status: [ReceiptStatusValues.DRAFT, ReceiptStatusValues.INCOMPLETE],
    }),
    [nursingHomeId]
  );

  const queryFn = useCallback(
    (api: ITransactionApi) => {
      if (params.nursingHomeId === undefined) {
        throw new Error('nursingHomeId is required');
      }
      return api.getPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    [params]
  );

  return usePrefetchApiQuery(
    useTransactionApi,
    RECEIPTS_KEY_NURSING_HOME(nursingHomeId, params),
    queryFn,
    nursingHomeId !== undefined
  );
};

export const useFirstOpenReceipts = (
  nursingHomeId: string | undefined,
  options?: UseQueryOptions<PaginatedReceiptResult>
): UseQueryResult<PaginatedReceiptResult> => {
  const params = useMemo(
    () => ({
      nursingHomeId,
      pageSize: 50,
      page: 0,
      status: [ReceiptStatusValues.DRAFT, ReceiptStatusValues.INCOMPLETE],
    }),
    [nursingHomeId]
  );

  return useReceiptsPaginated(params, options);
};

export const useTotalBalancePeriod = (
  params: GetTotalResidentBalanceQueryParams & {
    nursingHomeId: string | undefined;
  },
  options?: UseQueryOptions<TotalResidentBalanceResultDto>
): UseQueryResult<TotalResidentBalanceResultDto> => {
  return useApiQuery(
    useTransactionApi,
    TOTAL_BALANCE_PERIOD_KEY(params),
    (api) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getTotalBalancePeriod({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      enabled: params.nursingHomeId !== undefined,
      ...options,
    }
  );
};

export const useFilteredBalanceChangesPeriod = (
  params: GetFilteredBalanceChangesQueryParams & {
    nursingHomeId: string | undefined;
  },
  options?: UseQueryOptions<FilteredTransactionChangesResultDto>
): UseQueryResult<FilteredTransactionChangesResultDto> => {
  return useApiQuery(
    useTransactionApi,
    FILTERED_BALANCE_CHANGES_PERIOD_KEY(params),
    (api) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getFilteredBalanceChangesPeriod({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      enabled: params.nursingHomeId !== undefined,
      ...options,
    }
  );
};

const selectTransactionsPaginated = (
  data: PaginatedResultSet<GetTransactionsWithReceiptDto>
): PaginatedResultSet<GetTransactionsWithReceiptDto> => {
  return {
    ...data,
    computeAndSetLinks: data.computeAndSetLinks,
    data: data.data.map((row) =>
      mapGetTransactionWithReceiptResponseToDto(row)
    ),
  };
};

export const useTransactionsPaginated = (
  params: GetTransactionsPaginatedQueryParams & {
    nursingHomeId: string | undefined;
  },
  options?: UseQueryOptions<PaginatedResultSet<GetTransactionsWithReceiptDto>>
): UseQueryResult<PaginatedResultSet<GetTransactionsWithReceiptDto>> => {
  return useApiQuery(
    useTransactionApi,
    TRANSACTIONS_PAGINATED_KEY(params),
    (api) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getTransactionsPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      enabled: params.nursingHomeId !== undefined,
      select: selectTransactionsPaginated,
      ...options,
    }
  );
};

export const useGetTransactionsPaginatedCallback: () => (
  params: GetTransactionsPaginatedQueryParams & {
    nursingHomeId: string | undefined;
  }
) => Promise<PaginatedResultSet<GetTransactionsWithReceiptDto>> = () => {
  const api = useTransactionApi();
  return useCallback(
    async (
      params: GetTransactionsPaginatedQueryParams & {
        nursingHomeId: string | undefined;
      }
    ) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      const result = await api.getTransactionsPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });

      return selectTransactionsPaginated(result);
    },
    [api]
  );
};

const mapGetTransactionWithReceiptResponseToDto = (
  responseElement: GetTransactionsWithReceiptDto
): GetTransactionsWithReceiptDto => {
  return {
    ...responseElement,
    date: new Date(responseElement.date),
    receiptDate: responseElement.receiptDate
      ? new Date(responseElement.receiptDate)
      : undefined,
    cancelledSubmissionDate: responseElement.cancelledSubmissionDate
      ? new Date(responseElement.cancelledSubmissionDate)
      : undefined,
  };
};

const makeTransactionQueryOptions = (
  sourceTypes: readonly TransactionSourceType[],
  options?: UseQueryOptions<GetTransactionsWithReceiptDto[]>
): UseQueryOptions<GetTransactionsWithReceiptDto[]> => {
  // eslint-disable-next-line @grncdr/react-hooks/rules-of-hooks
  const select = useCallback(
    (
      data: GetTransactionsWithReceiptDto[]
    ): GetTransactionsWithReceiptDto[] => {
      if (!data) {
        return [];
      }
      let response;
      if (sourceTypes.length > 0) {
        response = data.filter((row) => sourceTypes?.includes(row.sourceType));
      } else {
        response = data;
      }
      return response.map((row) =>
        mapGetTransactionWithReceiptResponseToDto(row)
      );
    },
    [sourceTypes]
  );

  // eslint-disable-next-line @grncdr/react-hooks/rules-of-hooks
  const isDataEqual = useCallback(
    (
      oldData: GetTransactionsWithReceiptDto[] | undefined,
      newData: GetTransactionsWithReceiptDto[]
    ) => {
      return oldData ? oldData.length === newData.length : false;
    },
    []
  );

  return {
    // Taking advantage of transactions being append only - e.g. there can't be any changes in-between
    isDataEqual: isDataEqual,
    select: select,
    ...options,
  };
};

// We don't want a new object to be created every time the hook is called
const emptySourceTypes: readonly TransactionSourceType[] = [];

export const useTransactions = (
  residentId: string,
  sourceTypes: readonly TransactionSourceType[] = emptySourceTypes,
  options?: UseQueryOptions<GetTransactionsWithReceiptDto[]>
): UseQueryResult<GetTransactionsWithReceiptDto[]> => {
  return useApiQuery(
    useTransactionApi,
    getTransactionsKey(residentId),
    (api) => {
      return api.getTransactions(residentId);
    },
    makeTransactionQueryOptions(sourceTypes, options)
  );
};

export const useTransactionsForFamilyMember = (
  residentId: string | undefined,
  sourceTypes: readonly TransactionSourceType[] = [],
  options?: UseQueryOptions<GetTransactionsWithReceiptDto[]>
): UseQueryResult<GetTransactionsWithReceiptDto[]> => {
  const transactionQueryOptions = makeTransactionQueryOptions(sourceTypes, {
    ...options,
    enabled:
      options?.enabled === undefined
        ? residentId !== undefined
        : options?.enabled && residentId !== undefined,
  });
  const api = useTransactionApi(true);
  return useApiQuery(
    () => api,
    getTransactionsAsFamilyMemberKey(residentId ?? 'undefined'),
    (api) => {
      if (!residentId || !api) {
        throw new Error('residentId is undefined');
      }
      return api.getTransactionsAsFamilyMember(residentId);
    },
    transactionQueryOptions
  );
};

export const usePrefetchTransactionsForFamilyMember = (
  residentId: string | undefined
): void => {
  const getTransactionsAsFamilyMember = useCallback(
    (api: ITransactionApi) => {
      return api.getTransactionsAsFamilyMember(residentId as string);
    },
    [residentId]
  );
  return usePrefetchApiQuery(
    useTransactionApi,
    getTransactionsAsFamilyMemberKey(residentId as string),
    getTransactionsAsFamilyMember,
    residentId !== undefined
  );
};

export const useIncompleteReceiptCount = (
  nursingHomeId: string | undefined
): UseQueryResult<number> => {
  return useReceiptsPaginated(
    {
      nursingHomeId,
      page: 0,
      pageSize: 1,
      status: [ReceiptStatusValues.INCOMPLETE, ReceiptStatusValues.DRAFT],
    },
    {
      select: (data) => data?.meta?.totalItems ?? 0,
    }
  );
};

export const useUnsubmittedReceiptBatchCount = (
  nursingHomeId: string | undefined
): UseQueryResult<number> => {
  return useGetReceiptBatchListPaginated(
    {
      nursingHomeId,
      page: 0,
      pageSize: 1,
      state: null, // Only unsubmitted receipts
    },
    {
      select: (data) => data?.meta?.totalItems ?? 0,
    }
  );
};

export const usePrefetchTransactionTypes = (): void => {
  const getTypes = useCallback((api: ITransactionApi) => {
    return api.getTypes();
  }, []);
  return usePrefetchApiQuery(
    useTransactionApi,
    TRANSACTION_TYPES_KEY,
    getTypes
  );
};

export const useReceipt = (
  transactionId: string,
  nursingHomeId: string | undefined,
  queryOptions?: {
    optionalFields?: {
      linkedTransactionGroups?: boolean;
      receiptBatch?: boolean;
    };
  },
  options?: UseQueryOptions<ReceiptFull>
): UseQueryResult<ReceiptFull> => {
  usePrefetchResidents(nursingHomeId);
  usePrefetchTransactionTypes();
  usePrefetchServiceProviders(nursingHomeId);

  const select = useCallback((data: ReceiptFull): ReceiptFull => {
    return {
      ...data,
      date: new Date(data.date),
      created_on: new Date(data.created_on),
      submissionDate: data.submissionDate
        ? new Date(data.submissionDate)
        : undefined,
    };
  }, []);

  return useApiQuery(
    useTransactionApi,
    getReceiptKey(transactionId, queryOptions?.optionalFields),
    (api) => api.getOne(transactionId, queryOptions),
    {
      select,
      ...options,
    }
  );
};

export const useCreateReceipt = (): UseMutationResult<
  ReceiptFull,
  unknown,
  CreateReceiptDto
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<ReceiptFull, unknown, CreateReceiptDto>(
    CREATE_RECEIPT_KEY,
    (data) => api.create(data),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries(RECEIPTS_KEY),
          queryClient.invalidateQueries(RECEIPTS_AND_RECEIPT_BATCHES_PREFIX),
          invalidatePendingReceiptCount(queryClient),
        ]);
      },
    }
  );
  return result;
};

interface UpdateTransactionData extends UpdateTransactionDto {
  groupId?: string;
}

export const useUpdateTransaction = (queryInvalidationParams?: {
  nursingHomeId?: string;
}): UseMutationResult<Partial<ReceiptFull>, unknown, UpdateTransactionData> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    Partial<ReceiptFull>,
    unknown,
    UpdateTransactionData,
    {
      previousReceipt?: ReceiptFull;
    }
  >(UPDATE_TRANSACTION_KEY, (data) => api.update(data), {
    onMutate: async (data) => {
      await queryClient.cancelQueries(getReceiptKey(data.receipt_id));

      const previousReceipt = queryClient.getQueryData<ReceiptFull>(
        getReceiptKey(data.receipt_id),
        {
          predicate: (query) =>
            query.queryKey[0] === RECEIPTS_KEY &&
            query.queryKey[1] === data.receipt_id,
        }
      );

      if (!previousReceipt) {
        return { previousReceipt };
      }

      const newReceipt = { ...previousReceipt, ...data };

      const nullable = [
        'date',
        'resident_id',
        'amount',
        'amountInCent',
        'notes',
        'receipt_type_id',
        'service_provider',
      ] as const;
      for (const key of nullable) {
        if (newReceipt[key] === null) {
          delete newReceipt[key];
        }
      }

      queryClient.setQueryData(getReceiptKey(data.receipt_id), newReceipt);

      return { previousReceipt };
    },
    onError: (err, newTodo, context) => {
      if (context) {
        queryClient.setQueryData(
          getReceiptKey(newTodo.receipt_id),
          context.previousReceipt
        );
      }
    },
    onSuccess: async ({ id }, { groupId }) => {
      if (groupId) {
        await queryClient.invalidateQueries(
          CASH_TRANSACTION_GROUP_KEY(groupId)
        );
      }
      if (id) {
        await queryClient.invalidateQueries(getReceiptKey(id));
      }

      await queryClient.invalidateQueries(
        RECEIPTS_KEY_NURSING_HOME(queryInvalidationParams?.nursingHomeId)
      );
      await queryClient.invalidateQueries(
        RECEIPTS_AND_RECEIPT_BATCHES_KEY(queryInvalidationParams?.nursingHomeId)
      );
      return await queryClient.invalidateQueries(RECEIPTS_KEY);
    },
  });
  return result;
};

export const useCancelReceipt = (
  receiptId: string,
  nursingHomeId?: string
): UseMutationResult<void, unknown, CancelReceiptDto> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  return useMutation<void, unknown, CancelReceiptDto>(
    CANCEL_RECEIPT_KEY(receiptId),
    (data) => api.cancel(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getReceiptKey(receiptId));
        await queryClient.invalidateQueries(RESIDENTS_KEY(nursingHomeId));
        await invalidateAllResidentBalance(queryClient, nursingHomeId);
        return await queryClient.invalidateQueries(
          CANCEL_RECEIPT_KEY(receiptId)
        );
      },
    }
  );
};

export const useReceiptTypes = (
  options?: UseQueryOptions<IReceiptType[]>
): UseQueryResult<IReceiptType[]> => {
  return useApiQuery(
    useTransactionApi,
    TRANSACTION_TYPES_KEY,
    (api) => api.getTypes(),
    options
  );
};

export const useDeleteReceipt = (invalidationData?: {
  nursingHomeId?: string;
}): {
  deleteTransaction: UseMutationResult<void, unknown, string, unknown>;
} => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const deleteTransaction = useMutation(
    DELETE_TRANSACTION_KEY,
    (id: string) => api.delete(id),
    {
      onSettled: async () => {
        return await Promise.all([
          queryClient.invalidateQueries(
            RECEIPTS_KEY_NURSING_HOME(invalidationData?.nursingHomeId)
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(invalidationData?.nursingHomeId)
          ),
        ]);
      },
    }
  );
  return {
    deleteTransaction,
  };
};

export const useSubmitReceipts = (): {
  submitReceipts: UseMutationResult<
    Array<{
      id: string;
    }>,
    unknown,
    {
      receiptIds: string[];
      nursingHomeId?: string;
    },
    unknown
  >;
} => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const submitReceipts = useMutation<
    Array<{
      id: string;
    }>,
    unknown,
    {
      receiptIds: string[];
      nursingHomeId?: string;
    },
    unknown
  >(SUBMIT_RECEIPTS_KEY, (params) => api.submit(params.receiptIds), {
    onSettled: async (_data, _error, params) => {
      await Promise.all([
        queryClient.invalidateQueries(RECEIPTS_KEY),

        invalidateAllResidentBalance(queryClient, params.nursingHomeId),
        queryClient.invalidateQueries(RESIDENTS_KEY(params.nursingHomeId)),
        queryClient.invalidateQueries(
          RECEIPTS_AND_RECEIPT_BATCHES_KEY(params.nursingHomeId)
        ),
      ]);
    },
  });
  return {
    submitReceipts,
  };
};

export const useReceiptImages = (
  receiptImageIds?: string[]
): UseQueryResult<
  | {
      [key: string]: string | undefined;
    }
  | undefined
> => {
  return useApiQuery(
    useTransactionApi,
    getReceiptImagesKey(receiptImageIds?.toString()),
    (api) => {
      if (receiptImageIds && receiptImageIds?.length > 0) {
        return api.getReceiptImageUrls(receiptImageIds);
      }
      return Promise.resolve(undefined);
    },
    {
      enabled: receiptImageIds !== undefined,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      isDataEqual: (oldData, newData) => {
        return isEqual(oldData, newData);
      },
    }
  );
};

const RECEIPT_IMAGE_URLS_AND_TYPE_KEY = (imageIds?: string[]) => [
  'RECEIPT_IMAGE_URLS_AND_TYPE_KEY',
  ...(imageIds ?? []),
];
export const useReceiptImageUrlsAndType = (
  imageIds?: string[]
): UseQueryResult<
  Array<{
    imageId: string;
    imageUri?: string;
    previewUri?: string;
    fileType?: string;
  }>
> => {
  return useApiQuery(
    useTransactionApi,
    RECEIPT_IMAGE_URLS_AND_TYPE_KEY(imageIds),
    async (api) => {
      if (imageIds && imageIds?.length > 0) {
        const imageIdsToUris = await api.getReceiptImageUrls(imageIds);
        const imageIdsToTypes = await Promise.all(
          imageIds.map(async (imageId) => {
            const imageUri = imageIdsToUris?.[imageId];
            if (!imageUri) {
              return {
                imageId,
                imageUri: undefined,
                previewUri: undefined,
                fileType: undefined,
              };
            }

            const response = await fetch(imageUri);
            const extractedFileType =
              response.headers.get('content-type') ?? 'type-unknown';

            return {
              imageId,
              imageUri,
              previewUri: imageUri,
              fileType: extractedFileType,
            };
          })
        );

        return imageIdsToTypes;
      }
      return undefined;
    }
  );
};

export const useFetchReceiptImages = (): {
  fetchReceiptImages: (imagesIds: string[]) => Promise<
    | {
        [p: string]: string | undefined;
      }
    | undefined
  >;
} => {
  const client = useQueryClient();
  const api = useTransactionApi();
  const fetchReceiptImages = useCallback(
    (imagesIds: string[]) => {
      return client.fetchQuery(
        getReceiptImagesKey(imagesIds.toString()),
        () => api.getReceiptImageUrls(imagesIds),
        {
          cacheTime: 0,
          isDataEqual: (oldData, newData) => {
            return isEqual(oldData, newData);
          },
        }
      );
    },
    [client, api]
  );
  return { fetchReceiptImages };
};

export const useUploadReceiptImage: (
  uuid: () => string,
  computeMd5Hash: (file: File) => Promise<string>
) => {
  upload: UploadFile;
  uploadState: UploadState | undefined;
} = (uuid, computeMd5Hash) => {
  const api = useTransactionApi();

  const { upload, uploadState } = useUploadFile({
    uuid,
    getUploadUrl: api.getUploadUrl.bind(api),
    computeMd5Hash,
  });
  return {
    upload,
    uploadState,
  };
};

export const useGetUploadUrl: () => (
  receiptId: string,
  fileType: string,
  md5Hash: string
) => Promise<string | undefined> = () => {
  const api = useTransactionApi();
  return useCallback(
    (receiptId: string, fileType: string, md5Hash: string) => {
      return api.getUploadUrl(receiptId, fileType, md5Hash);
    },
    [api]
  );
};

export const useCreateManualDeposit = (): UseMutationResult<
  void,
  unknown,
  {
    data: ManualDepositDto;
    nursingHomeId?: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      data: ManualDepositDto;
      nursingHomeId?: string;
    }
  >(MANUAL_DEPOSIT_KEY, (params) => api.manualDeposit(params.data), {
    onSuccess: (_, params) => {
      return Promise.all([
        queryClient.invalidateQueries(
          getTransactionsKey(params.data.resident_id)
        ),
        invalidateAllResidentBalance(queryClient, params.nursingHomeId),
        invalidateResident(
          queryClient,
          params.data.resident_id,
          params.nursingHomeId
        ),
        queryClient.invalidateQueries(
          TRANSACTIONS_PAGINATED_KEY({
            nursingHomeId: params.nursingHomeId,
          })
        ),
      ]);
    },
  });
};

export const useCancelManualDeposit = (): UseMutationResult<
  void,
  unknown,
  {
    transactionId: string;
    residentId: string;
    cashTransaction?: CancelManualDepositDto;
    nursingHomeId?: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      transactionId: string;
      residentId: string;
      cashTransaction?: CancelManualDepositDto;
      nursingHomeId?: string;
    }
  >(
    MANUAL_DEPOSIT_CANCELLATION_KEY,
    ({ transactionId, cashTransaction }) =>
      api.cancelManualDeposit({
        transactionId,
        cashTransaction,
      }),
    {
      onSuccess: (_, data) => {
        return Promise.all([
          queryClient.invalidateQueries(getTransactionsKey(data.residentId)),
          invalidateResident(queryClient, data.residentId, data.nursingHomeId),
          invalidateAllResidentBalance(queryClient, data.nursingHomeId),
          queryClient.invalidateQueries(RESIDENTS_KEY(data.nursingHomeId)),
          queryClient.invalidateQueries(
            TRANSACTIONS_PAGINATED_KEY({
              nursingHomeId: data.nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            CASH_LIST_CONFIGURATION_KEY(data.nursingHomeId)
          ),
        ]);
      },
    }
  );
};

export const useCreateReceiptBatch = (): UseMutationResult<
  ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
  unknown,
  CreateReceiptBatchWithNursingHome
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
    unknown,
    CreateReceiptBatchWithNursingHome
  >(CREATE_RECEIPT_BATCH, (data) => api.createReceiptBatch({ body: data }), {
    onSuccess: async (data) => {
      await Promise.all([
        queryClient.invalidateQueries(
          RECEIPT_BATCH_LIST_KEY(data.nursingHomeId)
        ),
        queryClient.invalidateQueries(
          RECEIPT_BATCH_LIST_PAGINATED_KEY({
            nursingHomeId: data.nursingHomeId,
          })
        ),
        queryClient.invalidateQueries(
          RECEIPTS_AND_RECEIPT_BATCHES_KEY(data.nursingHomeId)
        ),
      ]);
    },
  });
  return result;
};

export const useUpdateReceiptBatch = (
  options?: UseMutationOptions<
    UpdateReceiptBatchResultDto,
    unknown,
    UpdateReceiptBatchWithNursingHome & {
      id: string;
    }
  >
): UseMutationResult<
  UpdateReceiptBatchResultDto,
  unknown,
  UpdateReceiptBatchWithNursingHome & {
    id: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    UpdateReceiptBatchResultDto,
    unknown,
    UpdateReceiptBatchWithNursingHome & {
      id: string;
    }
  >(
    UPDATE_RECEIPT_BATCH,
    (data) =>
      api.updateReceiptBatch({
        body: data,
        params: { batchId: data.id },
      }),
    {
      onSuccess: (data, variables, optionsIn) => {
        const promise = options?.onSuccess?.(data, variables, optionsIn);
        return Promise.all([
          ...(promise ? [promise] : []),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_KEY(data.nursingHomeId)
          ),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: data.nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(data.nursingHomeId)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(data.id)),
          queryClient.invalidateQueries(RECEIPT_BATCH_PAYMENT_STATUS(data.id)),
        ]);
      },
      ...options,
    }
  );
  return result;
};

export const usePatchReceiptBatch = (
  options?: UseMutationOptions<
    ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
    unknown,
    PatchReceiptBatchDto & {
      batchId: string;
    }
  >
): UseMutationResult<
  ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
  unknown,
  PatchReceiptBatchDto & {
    batchId: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
    unknown,
    PatchReceiptBatchDto & {
      batchId: string;
    }
  >(
    UPDATE_RECEIPT_BATCH,
    (data) =>
      api
        .patchReceiptBatch({
          body: data,
          params: { batchId: data.batchId },
        })
        .then((data) => ({
          ...data,
          receiptDate: data.receiptDate
            ? new Date(data.receiptDate)
            : undefined,
          createdOn: new Date(data.createdOn),
          lastUpdatedOn: data.lastUpdatedOn
            ? new Date(data.lastUpdatedOn)
            : undefined,
          submissionDate: data.submissionDate
            ? new Date(data.submissionDate)
            : undefined,
        })),
    {
      onSuccess: (data, variables, optionsIn) => {
        const promise = options?.onSuccess?.(data, variables, optionsIn);
        return Promise.all([
          ...(promise ? [promise] : []),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_KEY(data.nursingHomeId)
          ),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: data.nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(data.nursingHomeId)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(data.id)),
          queryClient.invalidateQueries(RECEIPT_BATCH_PAYMENT_STATUS(data.id)),
        ]);
      },
      ...options,
    }
  );
  return result;
};

export const usePatchReceiptBatchEntry = (
  options?: UseMutationOptions<
    {
      id: string;
    },
    unknown,
    CreateOrUpdateReceiptBatchEntryDto & {
      batchId: string;
      entryId: string;
      nursingHomeId: string | undefined;
    }
  >
): UseMutationResult<
  {
    id: string;
  },
  unknown,
  CreateOrUpdateReceiptBatchEntryDto & {
    batchId: string;
    entryId: string;
    nursingHomeId: string | undefined;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      id: string;
    },
    unknown,
    CreateOrUpdateReceiptBatchEntryDto & {
      batchId: string;
      entryId: string;
      nursingHomeId: string | undefined;
    }
  >(
    UPDATE_RECEIPT_BATCH_ENTRY,
    ({ batchId, entryId, ...data }) =>
      api.updateBatchEntry({
        body: data,
        params: {
          batchId,
          receiptId: entryId,
        },
      }),
    {
      onSuccess: (data, variables, optionsIn) => {
        const promise = options?.onSuccess?.(data, variables, optionsIn);
        return Promise.all([
          ...(promise ? [promise] : []),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_KEY(variables.nursingHomeId)
          ),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: variables.nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(variables.nursingHomeId)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(variables.batchId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_PAYMENT_STATUS(variables.batchId)
          ),
        ]);
      },
      ...options,
    }
  );
};

export const useUpdateReceiptBatchCashTransactionGroup = (): UseMutationResult<
  UpdateReceiptBatchResultDto,
  unknown,
  UpdateReceiptBatchCashTransactionGroupDto & {
    id: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    UpdateReceiptBatchResultDto,
    unknown,
    UpdateReceiptBatchCashTransactionGroupDto & {
      id: string;
    }
  >(
    UPDATE_RECEIPT_BATCH,
    (data) =>
      api.updateReceiptBatchCashTransactionGroup({
        body: data,
        params: { batchId: data.id },
      }),
    {
      onSuccess: (data) => {
        return Promise.all([
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_KEY(data.nursingHomeId)
          ),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: data.nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(data.nursingHomeId)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(data.id)),
          queryClient.invalidateQueries(RECEIPT_BATCH_PAYMENT_STATUS(data.id)),
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_KEY(data.id)),
        ]);
      },
    }
  );
  return result;
};

export const useGetReceiptBatchList = (
  nursingHomeId: string | undefined,
  options: UseQueryOptions<ReceiptBatchListDto[]> = {}
): UseQueryResult<ReceiptBatchListDto[]> => {
  const select = useCallback(
    (data: ReceiptBatchListDto[]): ReceiptBatchListDto[] => {
      // eslint-disable-next-line complexity
      const result = data.map((batch) => ({
        ...batch,
        receiptImageIds: batch.receiptImageIds ?? [],
        receiptDate: batch.receiptDate
          ? new Date(batch.receiptDate)
          : undefined,
        submissionDate: batch.submissionDate
          ? new Date(batch.submissionDate)
          : undefined,
        minReceiptDate: batch.minReceiptDate
          ? new Date(batch.minReceiptDate)
          : undefined,
        maxReceiptDate: batch.maxReceiptDate
          ? new Date(batch.maxReceiptDate)
          : undefined,
      }));

      return options?.select ? options.select(result) : result;
    },
    [options]
  );

  return useApiQuery(
    useTransactionApi,
    RECEIPT_BATCH_LIST_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw Error('nursingHomeId is undefined');
      }

      return api.getReceiptBatchList({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
      ...options,
      select,
    }
  );
};

export function useGetReceiptBatchListPaginated<
  TData = PaginatedResultSubset<ReceiptBatchPaginatedDto>
>(
  params: GetReceiptBatchesQueryDto & {
    nursingHomeId: string | undefined;
  },
  options: UseQueryOptions<
    PaginatedResultSubset<ReceiptBatchPaginatedDto>,
    unknown,
    TData
  > = {}
): UseQueryResult<TData> {
  const select = useCallback(
    (data: PaginatedResultSubset<ReceiptBatchPaginatedDto>): TData => {
      const result = {
        ...data,
        // eslint-disable-next-line complexity
        data: data.data.map((batch) => ({
          ...batch,
          receiptImageIds: batch.receiptImageIds ?? [],
          receiptDate: batch.receiptDate
            ? new Date(batch.receiptDate)
            : undefined,
          submissionDate: batch.submissionDate
            ? new Date(batch.submissionDate)
            : undefined,
          minReceiptDate: batch.minReceiptDate
            ? new Date(batch.minReceiptDate)
            : undefined,
          maxReceiptDate: batch.maxReceiptDate
            ? new Date(batch.maxReceiptDate)
            : undefined,
        })),
      };

      // @ts-expect-error // type depends on if options.select is passed or not. Hard to type correctly
      return options?.select ? options.select(result) : result;
    },
    [options]
  );
  return useApiQuery(
    useTransactionApi,
    RECEIPT_BATCH_LIST_PAGINATED_KEY(params),
    (api) => {
      if (params.nursingHomeId === undefined) {
        throw Error('nursingHomeId is undefined');
      }
      if (!api) {
        throw Error('api is undefined');
      }
      return api.getReceiptBatchListPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      ...options,
      select,
      enabled: options.enabled ?? params.nursingHomeId !== undefined,
    }
  );
}

export const useGetFirstOpenReceiptBatches = (
  nursingHomeId: string | undefined,
  options?: UseQueryOptions<PaginatedResultSubset<ReceiptBatchPaginatedDto>>
): UseQueryResult<PaginatedResultSubset<ReceiptBatchPaginatedDto>> => {
  const params = useMemo<
    GetReceiptBatchesQueryDto & {
      nursingHomeId: string | undefined;
    }
  >(
    () => ({
      page: 0,
      pageSize: 50,
      nursingHomeId,
      state: null,
      sort: 'createdOn',
      sortOrder: 'desc',
    }),
    [nursingHomeId]
  );

  return useGetReceiptBatchListPaginated(params, options);
};

export const useGetReceiptsAndReceiptBatchesInfiniteQuery = ({
  nursingHomeId,
  ...params
}: Omit<ReceiptsAndReceiptBatchesQueryParams, 'page'> & {
  nursingHomeId: string | undefined;
}): UseInfiniteQueryResult<
  Omit<PaginatedResultSet<ReceiptsAndReceiptBatchesDto>, 'computeAndSetLinks'>
> => {
  return useApiInfiniteQuery(
    useTransactionApi,
    RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId, params),
    (api, page) => {
      if (nursingHomeId === undefined) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getReceiptsAndReceiptBatchesPaginated({
        params: {
          nursingHomeId,
          page,
          ...params,
        },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
      select: (data) => {
        return {
          ...data,
          pages: [
            ...data.pages.map((page) => ({
              ...page,
              // eslint-disable-next-line complexity
              data: page.data.map((item) => ({
                ...item,
                createdAt: new Date(item.createdAt),
                submittedAt: item.submittedAt
                  ? new Date(item.submittedAt)
                  : undefined,
                receiptDate: item.receiptDate
                  ? new Date(item.receiptDate)
                  : undefined,
                ...(item.type === 'receipt-batch'
                  ? {
                      minReceiptDate: item.minReceiptDate
                        ? new Date(item.minReceiptDate)
                        : undefined,
                      maxReceiptDate: item.maxReceiptDate
                        ? new Date(item.maxReceiptDate)
                        : undefined,
                    }
                  : {
                      minReceiptDate: undefined,
                      maxReceiptDate: undefined,
                    }),
              })),
            })),
          ],
        };
      },
    }
  );
};

const getReceiptBatchSelect = (data: ReceiptBatchGetDto) => ({
  ...data,
  receiptDate: data.receiptDate ? new Date(data.receiptDate) : undefined,
  cashListTransactionGroupData: data.cashListTransactionGroupData
    ? {
        ...data.cashListTransactionGroupData,
        updateDate: new Date(data.cashListTransactionGroupData.updateDate),
      }
    : undefined,
  submissionDate: data.submissionDate
    ? new Date(data.submissionDate)
    : undefined,
  receiptImageIds: data.receiptImageIds ?? [],
  receiptBatchEntries: data.receiptBatchEntries.map((entry) => ({
    ...entry,
    receiptDate: entry.receiptDate ? new Date(entry.receiptDate) : undefined,
    receiptImageIds: entry.receiptImageIds ?? [],
  })),
});

export const useGetReceiptBatchCallback: () => (
  batchId: string
) => Promise<ReceiptBatchGetDto> = () => {
  const api = useTransactionApi();
  return useCallback(
    async (batchId: string) => {
      const result = await api.getReceiptBatch({ params: { batchId } });
      return getReceiptBatchSelect(result);
    },
    [api]
  );
};

export const useGetReceiptBatch = (
  batchId?: string
): UseQueryResult<ReceiptBatchGetDto> => {
  return useApiQuery(
    useTransactionApi,
    RECEIPT_BATCH_KEY(batchId),
    (api) => {
      if (!batchId) {
        throw Error('batchId is undefined');
      }
      return api.getReceiptBatch({ params: { batchId } });
    },
    {
      enabled: batchId !== undefined,
      select: getReceiptBatchSelect,
    }
  );
};

export const usePrefetchReceiptBatch = (): ((
  batchId: string
) => Promise<void>) => {
  const api = useTransactionApi();
  const client = useQueryClient();
  return useCallback(
    async (batchId: string) => {
      await client.prefetchQuery(RECEIPT_BATCH_KEY(batchId), () =>
        api.getReceiptBatch({ params: { batchId } })
      );
    },
    [api, client]
  );
};

export const useDeleteReceiptBatch = (
  nursingHomeId?: string
): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    batchId: string;
  }
> => {
  const queryClient = useQueryClient();
  const api = useTransactionApi();
  const result = useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      batchId: string;
    }
  >(
    DELETE_RECEIPT_BATCH,
    ({ batchId }) => api.deleteReceiptBatch({ params: { batchId } }),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(RECEIPT_BATCH_LIST_KEY(nursingHomeId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({ nursingHomeId })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId)
          ),
        ]);
      },
    }
  );
  return result;
};

export const useCreateReceiptBatchJob = (): UseMutationResult<
  {
    id: string;
  },
  unknown,
  {
    batchId: string;
    action?: CreateReceiptBatchJobAction;
    nursingHomeId: string | undefined;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      id: string;
    },
    unknown,
    {
      batchId: string;
      action?: CreateReceiptBatchJobAction;
      nursingHomeId: string | undefined;
    }
  >(
    CREATE_RECEIPT_BATCH_JOB,
    (data) =>
      api.createReceiptBatchJob({
        params: { receiptBatchId: data.batchId },
        body: {
          action: data.action,
        },
      }),
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_KEY(variables.nursingHomeId)
          ),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: variables.nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(variables.batchId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_PAYMENT_STATUS(variables.batchId)
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(variables.nursingHomeId)
          ),
        ]);
      },
    }
  );
  return result;
};

export const useGetReceiptBatchJob = (
  batchId?: string
): UseQueryResult<ReceiptBatchJob> => {
  return useApiQuery(
    useTransactionApi,
    RECEIPT_BATCH_JOB_KEY(batchId),
    (api) => {
      if (!batchId) {
        throw Error('batchId is undefined');
      }
      return api.getReceiptBatchJob({ params: { receiptBatchJobId: batchId } });
    },
    { enabled: batchId !== undefined && batchId !== 'NO_JOB_KEY' }
  );
};

export const DELETE_RECEIPT_BATCH_ENTRY_KEY = ['deleteReceiptBatchEntry'];
export const useDeleteReceiptBatchEntry = ({
  nursingHomeId,
}: {
  nursingHomeId: string;
}): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    batchId: string;
    entryId: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      batchId: string;
      entryId: string;
    }
  >(
    DELETE_RECEIPT_BATCH_ENTRY_KEY,
    (data) =>
      api.deleteReceiptBatchEntry({
        params: {
          batchId: data.batchId,
          entryId: data.entryId,
        },
      }),
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(RECEIPT_BATCH_KEY(variables.batchId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_PAYMENT_STATUS(variables.batchId)
          ),
          queryClient.invalidateQueries(RECEIPT_BATCH_LIST_KEY(nursingHomeId)),
          queryClient.invalidateQueries(
            RECEIPT_BATCH_LIST_PAGINATED_KEY({
              nursingHomeId: nursingHomeId,
            })
          ),
          queryClient.invalidateQueries(
            RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId)
          ),
        ]);
      },
    }
  );
  return result;
};

export const useGetResidentBalanceUntilDate = (
  residentId?: string,
  date?: Date
): UseQueryResult<{
  residentId: string;
  balance: number;
}> => {
  return useApiQuery(
    useTransactionApi,
    RESIDENT_BALANCE_KEY(residentId, date),
    (api) => {
      if (!residentId) {
        throw Error('residentId is undefined');
      }
      if (!date) {
        throw Error('date is undefined');
      }

      return api.getResidentBalanceUntilDate({
        params: {
          residentId,
          date,
        },
      });
    },
    { enabled: residentId !== undefined && date !== undefined }
  );
};
