import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getApiBaseUrl,
  getPhoenixApiTenantUrl,
  modify,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';
import type {
  AddRecurringItemResidentV2Dto,
  CountryDto,
  CreateRecurringItemWithResidentsV2Dto,
  CreateResidentDto,
  ExitResidentDto,
  FamilyMemberDto,
  FamilyMemberWithIdDto,
  GetResidentsQueryParams,
  ImportFamilyMemberDto,
  ImportFamilyMemberResponseDto,
  NursingHomeDto,
  PutResidentServiceProvidersDto,
  RecurringItemTaxCodeDto,
  RecurringItemTransactionDto,
  RecurringItemTransactionsQueryFilters,
  RecurringItemV2Dto,
  RecurringItemV2WithResidentV2Dto,
  RecurringItemWithResidentsV2Dto,
  Resident,
  ResidentConfiguration,
  ResidentListItemDto,
  ResidentListItemV2Dto,
  ResidentSettlementChargesDto,
  ResidentState,
  ServiceProviderListDto,
  SettlementDto,
  SettlementFormDataDto,
  UpdateNursingHomeDto,
  UpdateNursingHomePaymentSettingsDto,
  UpdateResidentCashPayerStateDto,
  UpdateResidentConfigurationDto,
  UpdateResidentDto,
} from '@pflegenavi/shared/api';
import {
  endpoints,
  ResidentServiceError,
  SettlementServiceError,
} from '@pflegenavi/shared/api';
import { parseISO } from 'date-fns';
import type { PaginatedResultSet } from '@pflegenavi/shared/utils';

export interface IResidentApi extends Api {
  /** @deprecated Use getPaginated instead, but be aware that it will return amounts in cents */
  get(
    nursingHomeId: string,
    states?: ResidentState[],
    additionalFields?: {
      pendingReceiptCount?: boolean;
      residentPaymentInfo?: boolean;
    }
  ): Promise<ResidentListItemDto[]>;

  getOne(residentId: string): Promise<Resident>;

  create(body: CreateResidentDto): Promise<Resident>;

  update(body: UpdateResidentDto): Promise<Resident>;

  addNewFamilyMember(
    residentId: string,
    body: FamilyMemberDto
  ): Promise<FamilyMemberWithIdDto>;

  importFamilyMembers(dataIn: {
    body: ImportFamilyMemberDto;
  }): Promise<ImportFamilyMemberResponseDto>;

  proceedToAwaitingSettlement(residentId: string): Promise<void>;

  goBackToExited(residentId: string): Promise<void>;

  confirmResidentSettlement(
    residentId: string,
    settlementCharges: ResidentSettlementChargesDto[]
  ): Promise<void>;

  updateNursingHome(body: UpdateNursingHomeDto): Promise<NursingHomeDto>;

  getNursingHome(nursingHomeId: string): Promise<NursingHomeDto>;

  patchNursingHomePaymentSettings(dataIn: {
    params: {
      nursingHomeId: string;
    };
    body: UpdateNursingHomePaymentSettingsDto;
  }): Promise<{
    success: boolean;
  }>;

  getConfiguration(residentId: string): Promise<ResidentConfiguration>;

  updateConfiguration(
    data: UpdateResidentConfigurationDto
  ): Promise<ResidentConfiguration>;

  updateResidentCashPayerStatus(
    residentId: string,
    data: UpdateResidentCashPayerStateDto
  ): Promise<{
    success: boolean;
  }>;

  getPaginated(
    dataIn: GetResidentsQueryParams,
    page: number,
    pageSize: number
  ): Promise<PaginatedResultSet<ResidentListItemV2Dto>>;

  getRecurringItems(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<RecurringItemV2Dto[]>;

  getRecurringItemResidents(dataIn: {
    params: {
      recurringItemId: string;
    };
  }): Promise<RecurringItemWithResidentsV2Dto>;

  getRecurringItemTransactions(dataIn: {
    params: {
      recurringItemId: string;
      filters?: RecurringItemTransactionsQueryFilters;
    };
  }): Promise<RecurringItemTransactionDto[]>;

  getRecurringItemsByResident(dataIn: {
    params: {
      residentId: string;
    };
  }): Promise<RecurringItemV2WithResidentV2Dto[]>;

  createRecurringItem(dataIn: {
    body: CreateRecurringItemWithResidentsV2Dto;
  }): Promise<RecurringItemWithResidentsV2Dto>;

  deleteRecurringItem(dataIn: {
    params: {
      recurringItemId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  addResidentToRecurringItem(dataIn: {
    params: {
      recurringItemId: string;
    };
    body: Omit<AddRecurringItemResidentV2Dto, 'recurringItemId'>;
  }): Promise<{
    success: boolean;
  }>;

  updateResidentStartDate(dataIn: {
    params: {
      recurringItemId: string;
      residentId: string;
    };
    body: {
      startDate: Date;
    };
  }): Promise<{
    success: boolean;
  }>;

  removeResidentToRecurringItem(dataIn: {
    params: {
      recurringItemId: string;
      residentId: string;
    };
    body: {
      chargeResident: boolean;
      endDate?: Date;
    };
  }): Promise<{
    success: boolean;
  }>;

  getCountries(): Promise<{
    data: CountryDto[];
  }>;

  getTaxCodesByCountry(dataIn: {
    params: {
      countryId: string;
    };
  }): Promise<{
    data: RecurringItemTaxCodeDto[];
  }>;

  superadmin_createTransactions(dataIn: {
    body: {
      date?: Date;
    };
    params: undefined;
  }): Promise<{
    success: boolean;
  }>;

  exitResident(dataIn: {
    body: ExitResidentDto;
    params: {
      residentId: string;
    };
  }): Promise<void>;

  getResidentSettlement(dataIn: {
    body: undefined;
    params: {
      residentId: string;
    };
  }): Promise<SettlementDto>;

  archiveResident(dataIn: {
    body?: SettlementFormDataDto;
    params: {
      residentId: string;
    };
  }): Promise<{
    success: true;
  }>;

  sendFamilyMemberInvite(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  getServiceProviderListForResident(dataIn: {
    params: {
      residentId: string;
    };
  }): Promise<ServiceProviderListDto[]>;

  putServiceProviderListForResident(dataIn: {
    params: {
      residentId: string;
    };
    body: PutResidentServiceProvidersDto;
  }): Promise<{
    success: boolean;
  }>;
}

interface DuplicateResidentAccountingIdError extends Error {
  errorCode: ResidentServiceError.DUPLICATE_RESIDENT_ACCOUNTING_ID;
}

// interface ResidentAccountingIdAlreadySetError extends Error {
//   errorCode: ResidentServiceError.RESIDENT_ACCOUNTING_ID_ALREADY_SET
// }

// eslint-disable-next-line complexity
export const isDuplicateResidentAccountingIdError = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): error is DuplicateResidentAccountingIdError => {
  return (
    ('errorCode' in error &&
      error.errorCode ===
        ResidentServiceError.DUPLICATE_RESIDENT_ACCOUNTING_ID) ||
    ('errors' in error &&
      'resident' in error.errors &&
      'resident_accounting_id' in error.errors.resident &&
      error.errors.resident.resident_accounting_id.includes(
        'has already been taken'
      ))
  );
};

interface StartDateAfterExitDateError extends Error {
  errorCode: SettlementServiceError.START_DATE_AFTER_EXIT_DATE;
  recurringItems: Array<{
    name: string;
    id: string;
  }>;
}

export const isStartDateAfterExitDateError = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): error is StartDateAfterExitDateError => {
  return (
    'errorCode' in error &&
    error.errorCode === SettlementServiceError.START_DATE_AFTER_EXIT_DATE
  );
};

export class ResidentApi implements IResidentApi {
  baseUrl: string;
  public getRecurringItems: IResidentApi['getRecurringItems'];
  public createRecurringItem: IResidentApi['createRecurringItem'];
  public getRecurringItemResidents: IResidentApi['getRecurringItemResidents'];
  public getRecurringItemTransactions: IResidentApi['getRecurringItemTransactions'];
  public getRecurringItemsByResident: IResidentApi['getRecurringItemsByResident'];
  public deleteRecurringItem: IResidentApi['deleteRecurringItem'];
  public addResidentToRecurringItem: IResidentApi['addResidentToRecurringItem'];
  public updateResidentStartDate: IResidentApi['updateResidentStartDate'];
  public removeResidentToRecurringItem: IResidentApi['removeResidentToRecurringItem'];
  public getCountries: IResidentApi['getCountries'];
  public getTaxCodesByCountry: IResidentApi['getTaxCodesByCountry'];
  public superadmin_createTransactions: IResidentApi['superadmin_createTransactions'];
  public exitResident: IResidentApi['exitResident'];
  public getResidentSettlement: IResidentApi['getResidentSettlement'];
  public archiveResident: IResidentApi['archiveResident'];
  public sendFamilyMemberInvite: IResidentApi['sendFamilyMemberInvite'];
  public patchNursingHomePaymentSettings: IResidentApi['patchNursingHomePaymentSettings'];
  public importFamilyMembers: IResidentApi['importFamilyMembers'];
  public getServiceProviderListForResident: IResidentApi['getServiceProviderListForResident'];
  public putServiceProviderListForResident: IResidentApi['putServiceProviderListForResident'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    const baseUrl = getApiBaseUrl(tenantId, apiUrl);
    const phoenixBaseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);
    this.baseUrl = baseUrl;

    this.superadmin_createTransactions = modify<
      {
        date?: Date;
      },
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      undefined
    >({
      authContext,
      url: `${baseUrl}/${endpoints.recurringItems}/${endpoints.recurringItemsPostTransactions}`,
    });

    this.getRecurringItems = get({
      authContext,
      url: ({ nursingHomeId }) =>
        `${baseUrl}/${endpoints.recurringItems}/${endpoints.recurringItemsGetAll}?nursingHomeId=${nursingHomeId}`,
    });
    this.createRecurringItem = modify<
      CreateRecurringItemWithResidentsV2Dto,
      RecurringItemWithResidentsV2Dto
    >({
      authContext,
      url: `${baseUrl}/${endpoints.recurringItems}/${endpoints.recurringItemsPost}`,
      method: 'POST',
    });
    this.getRecurringItemResidents = get({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.recurringItems
        }/${endpoints.recurringItemsGetResidents.replace(
          ':recurringItemId',
          params.recurringItemId
        )}`,
    });
    this.getRecurringItemsByResident = get({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.recurringItems
        }/${endpoints.recurringItemsGetByResident.replace(
          ':residentId',
          params.residentId
        )}`,
      transform: (result) => {
        return result.map((row: any) => ({
          ...row,
          resident: {
            ...row.resident,
            startDate: parseISO(row.resident.startDate),
            endDate: parseISO(row.resident.endDate),
          },
        }));
      },
    });
    this.getRecurringItemTransactions = get({
      authContext,
      url: ({ recurringItemId, filters }) => {
        const queryParams = [];
        if (filters?.dateFrom) {
          queryParams.push(`dateFrom=${filters.dateFrom.toISOString()}`);
        }
        if (filters?.dateEnd) {
          queryParams.push(`dateEnd=${filters.dateEnd.toISOString()}`);
        }

        const paramsString =
          queryParams.length === 0 ? '' : `?${queryParams.join('&')}`;

        return `${baseUrl}/${
          endpoints.recurringItems
        }/${endpoints.recurringItemsGetTransactions.replace(
          ':recurringItemId',
          recurringItemId
        )}${paramsString}`;
      },
      transform: (result) => {
        return result.map((row: any) => ({
          ...row,
          transaction: {
            ...row.transaction,
            creationDate: parseISO(row.transaction.creationDate),
          },
          startDate: parseISO(row.startDate),
          endDate: parseISO(row.endDate),
        }));
      },
    });

    this.deleteRecurringItem = modify<
      undefined,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        recurringItemId: string;
      }
    >({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.recurringItems
        }/${endpoints.recurringItemsDelete.replace(
          ':recurringItemId',
          params.recurringItemId
        )}`,
      method: 'DELETE',
    });

    this.addResidentToRecurringItem = modify<
      Omit<AddRecurringItemResidentV2Dto, 'recurringItemId'>,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        recurringItemId: string;
      }
    >({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.recurringItems
        }/${endpoints.recurringItemsPostResident.replace(
          ':recurringItemId',
          params.recurringItemId
        )}`,
      method: 'POST',
    });

    this.updateResidentStartDate = modify<
      {
        startDate: Date;
      },
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        recurringItemId: string;
        residentId: string;
      }
    >({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.recurringItems
        }/${endpoints.recurringItemsPutResidentStartDate
          .replace(':recurringItemId', params.recurringItemId)
          .replace(':residentId', params.residentId)}`,
      method: 'PUT',
    });

    this.removeResidentToRecurringItem = modify<
      {
        chargeResident: boolean;
      },
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        recurringItemId: string;
        residentId: string;
      }
    >({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.recurringItems
        }/${endpoints.recurringItemsDeleteResident
          .replace(':recurringItemId', params.recurringItemId)
          .replace(':residentId', params.residentId)}`,
      method: 'DELETE',
    });

    this.getCountries = get({
      authContext,
      url: `${phoenixBaseUrl}/${endpoints.countries}`,
    });

    this.getTaxCodesByCountry = get({
      authContext,
      url: ({ countryId }) =>
        `${phoenixBaseUrl}/${endpoints.taxCodesByCountryId.replace(
          ':countryId',
          countryId
        )}`,
    });

    this.exitResident = modify({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.residentsSettlement
        }/${endpoints.residentsSettlementPost.replace(
          ':resident_id',
          params.residentId
        )}`,
      method: 'POST',
    });

    this.getResidentSettlement = get({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.residentsSettlement
        }/${endpoints.residentsSettlementGet.replace(
          ':resident_id',
          params.residentId
        )}`,
    });

    this.archiveResident = modify({
      authContext,
      method: 'POST',
      url: (params) =>
        `${baseUrl}/${
          endpoints.residentsSettlement
        }/${endpoints.residentsSettlementPostResidentArchived.replace(
          ':resident_id',
          params.residentId
        )}`,
    });

    this.sendFamilyMemberInvite = modify({
      authContext,
      method: 'POST',
      url: (params) =>
        `${baseUrl}/${
          endpoints.residents
        }/${endpoints.residentsSendFamilyMemberInvite
          .replace(':resident_id', params.residentId)
          .replace(':family_member_id', params.familyMemberId)}`,
    });

    this.importFamilyMembers = modify<
      ImportFamilyMemberDto,
      ImportFamilyMemberResponseDto
    >({
      authContext,
      url: `${baseUrl}/${endpoints.residents}/${endpoints.residentsImportFamilyMemberPost}`,
      method: 'POST',
    });

    this.patchNursingHomePaymentSettings = modify<
      UpdateNursingHomePaymentSettingsDto,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        nursingHomeId: string;
      }
    >({
      authContext,
      method: 'PATCH',
      url: (params) =>
        `${baseUrl}/${
          endpoints.nursingHomes
        }/${endpoints.nursingHomesPatchPaymentSettings.replace(
          ':nursingHomeId',
          params.nursingHomeId
        )}`,
    });

    this.getServiceProviderListForResident = get({
      authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.residents
        }/${endpoints.residentsServiceProviders.replace(
          ':resident_id',
          params.residentId
        )}`,
    });

    this.putServiceProviderListForResident = modify<
      PutResidentServiceProvidersDto,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        residentId: string;
      }
    >({
      authContext,
      method: 'PUT',
      url: (params) =>
        `${baseUrl}/${
          endpoints.residents
        }/${endpoints.residentsServiceProviders.replace(
          ':resident_id',
          params.residentId
        )}`,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get headers(): Headers {
    return new Headers({
      'content-type': 'application/json',
    });
  }

  /**
   * @deprecated use getPaginated instead, but be aware that the amounts are in cents
   */
  async get(
    nursingHomeId: string,
    states: ResidentState[] = [],
    additionalFields?: {
      pendingReceiptCount?: boolean;
      residentPaymentInfo?: boolean;
    }
  ): Promise<ResidentListItemDto[]> {
    const query = states.map((state, i) => `states[${i}]=${state}`);
    if (additionalFields?.pendingReceiptCount) {
      query.push('additionalFields[pendingReceiptCount]=true');
    }
    if (additionalFields?.residentPaymentInfo) {
      query.push('additionalFields[residentPaymentInfo]=true');
    }
    query.push(`nursingHomeId=${nursingHomeId}`);

    const queryString = query.length > 0 ? `?${query.join('&')}` : '';
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.residents}/${endpoints.residentsGetAll}${queryString}`
    );
    return await result.json();
  }

  // eslint-disable-next-line complexity
  async getPaginated(
    dataIn: GetResidentsQueryParams,
    page: number,
    pageSize: number
  ): Promise<PaginatedResultSet<ResidentListItemV2Dto>> {
    const query =
      dataIn.states?.map((state, i) => `states[${i}]=${state}`) ?? [];

    query.push(`nursingHomeId=${dataIn.nursingHomeId}`);

    if (dataIn.familyMemberIds !== undefined) {
      dataIn.familyMemberIds.forEach((id, i) => {
        query.push(`familyMemberIds[${i}]=${id}`);
      });
    }

    if (dataIn.balance !== undefined) {
      Object.entries(dataIn.balance).forEach(([key, value]) => {
        query.push(`balance[${key}]=${value}`);
      });
    }

    if (dataIn.balanceState !== undefined) {
      query.push(`balanceState=${dataIn.balanceState}`);
    }

    if (dataIn.search !== undefined) {
      query.push(`search[value]=${dataIn.search.value}`);
      dataIn.search.fields.forEach((field, i) => {
        query.push(`search[fields][${i}]=${field}`);
      });
    }

    if (dataIn.options?.additionalFields?.pendingReceiptCount) {
      query.push('options[additionalFields][pendingReceiptCount]=true');
    }
    if (dataIn.options?.additionalFields?.familyMembers) {
      query.push('options[additionalFields][familyMembers]=true');
    }

    query.push(`page=${page}`);
    query.push(`pageSize=${pageSize}`);

    const queryString = query.length > 0 ? `?${query.join('&')}` : '';

    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.residents}/${endpoints.residentsGetAllPaginated}${queryString}`
    );

    return await result.json();
  }

  async getOne(residentId: string): Promise<Resident> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.residents}/${endpoints.residentsGet.replace(
        ':resident_id',
        residentId
      )}`
    );
    return await result.json();
  }

  async create(body: CreateResidentDto): Promise<Resident> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.residents}/${endpoints.residentsPostResident}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async addNewFamilyMember(
    residentId: string,
    body: FamilyMemberDto
  ): Promise<FamilyMemberWithIdDto> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.residents
      }/${endpoints.residentsPutFamilyMember.replace(
        ':resident_id',
        residentId
      )}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async update(body: UpdateResidentDto): Promise<Resident> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.residents}/${endpoints.residentsPutResident}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async getConfiguration(residentId: string): Promise<ResidentConfiguration> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.residents
      }/${endpoints.residentsGetConfiguration.replace(
        ':resident_id',
        residentId
      )}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );
    return result.json();
  }

  async updateConfiguration(
    data: UpdateResidentConfigurationDto
  ): Promise<ResidentConfiguration> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.residents}/${endpoints.residentsPostConfiguration}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async updateResidentCashPayerStatus(
    residentId: string,
    data: UpdateResidentCashPayerStateDto
  ): Promise<{
    success: boolean;
  }> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.residents
      }/${endpoints.residentCashPayerStatus.replace(
        ':resident_id',
        residentId
      )}`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async updateNursingHome(body: UpdateNursingHomeDto): Promise<NursingHomeDto> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.nursingHomes}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: this.headers,
      }
    );
    return result.json();
  }

  async getNursingHome(nursingHomeId: string): Promise<NursingHomeDto> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.nursingHomes
      }/${endpoints.nursingHomesGetById.replace(
        ':nursingHomeId',
        nursingHomeId
      )}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );
    return result.json();
  }

  async confirmResidentSettlement(
    residentId: string,
    settlementCharges: ResidentSettlementChargesDto[]
  ): Promise<void> {
    await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.residentsSettlement
      }/${endpoints.residentsSettlementPutRecurringItemsCharged.replace(
        ':resident_id',
        residentId
      )}`,
      {
        method: 'PUT',
        body: JSON.stringify({ settlementCharges }),
        headers: this.headers,
      }
    );
  }

  async proceedToAwaitingSettlement(residentId: string): Promise<void> {
    await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.residentsSettlement
      }/${endpoints.residentsSettlementPutReceiptsFrozen.replace(
        ':resident_id',
        residentId
      )}`,
      {
        method: 'PUT',
        headers: this.headers,
      }
    );
  }

  async goBackToExited(residentId: string): Promise<void> {
    await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.residentsSettlement
      }/${endpoints.residentsSettlementPutReceiptsUnfrozen.replace(
        ':resident_id',
        residentId
      )}`,
      {
        method: 'PUT',
        headers: this.headers,
      }
    );
  }
}
