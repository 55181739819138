import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import { createContext } from 'react';
import type { IEmployeesApi } from './api';
import { EmployeesApi } from './api';
import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import type {
  EmployeeListItemDto,
  UpdateEmployeeState,
} from '@pflegenavi/shared/api';

const ApiContext = createContext<IEmployeesApi | undefined>(undefined);
const { useApi: useEmployeesApi, ApiProvider: EmployeesApiProvider } =
  makeApiProvider({
    name: 'Employees',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new EmployeesApi(tenantId, auth, apiUrl),
  });

export { useEmployeesApi, EmployeesApiProvider };
export type { IEmployeesApi } from './api';

const GET_ALL_EMPLOYEES_MAIN_KEY = 'getAllEmployees';
const GET_ALL_EMPLOYEES_KEY = (nursingHomeId: string) => [
  GET_ALL_EMPLOYEES_MAIN_KEY,
  nursingHomeId,
];
const UPDATE_EMPLOYEE_STATE = 'updateEmployeeState';

export const useGetAllEmployees = ({
  nursingHomeId,
  options,
}: {
  nursingHomeId?: string;
  options?: UseQueryOptions<EmployeeListItemDto[]>;
}): UseQueryResult<EmployeeListItemDto[]> => {
  if (!nursingHomeId) {
    throw new Error('nursingHomeId is required');
  }

  return useApiQuery(
    useEmployeesApi,
    GET_ALL_EMPLOYEES_KEY(nursingHomeId),
    (api) => {
      return api.getAllEmployees({
        params: {
          nursingHomeId,
        },
      });
    },
    {
      ...options,
    }
  );
};

export const useUpdateEmployeeState = (): UseMutationResult<
  {
    success: true;
  },
  unknown,
  UpdateEmployeeState & { employeeId: string }
> => {
  const api = useEmployeesApi();
  const queryClient = useQueryClient();

  return useMutation<
    {
      success: true;
    },
    unknown,
    UpdateEmployeeState & { employeeId: string }
  >(
    UPDATE_EMPLOYEE_STATE,
    ({ employeeId, ...body }) => {
      return api.updateEmployeeState({
        params: { employeeId },
        body,
      });
    },
    {
      onSuccess: async (_, params) => {
        await queryClient.invalidateQueries(
          GET_ALL_EMPLOYEES_KEY(params.nursingHomeId)
        );
      },
    }
  );
};
