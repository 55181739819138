import type { UseMutationResult } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import { createContext } from 'react';
import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import type {
  CreateResidentParams,
  CreateResidentResult,
  IResidentPhoenixApi,
} from './api';
import { ResidentPhoenixApi } from './api';
import type {
  FamilyMemberPhoenix,
  ImportFamilyMemberPhoenix,
  ImportFamilyMemberResponsePhoenix,
  Resident,
} from '@pflegenavi/shared/api';
import { PAYMENT_STATUS_KEY } from '@pflegenavi/shared/constants';
import {
  getResidentKey,
  invalidateResident,
  invalidateResidentsKeys,
} from '../resident';
import { PAYMENT_INFO_KEY } from '../payment';

export type { InitialBalance } from './api';

const ApiContext = createContext<IResidentPhoenixApi | undefined>(undefined);
const {
  useApi: useResidentPhoenixApi,
  ApiProvider: ResidentPhoenixApiProvider,
} = makeApiProvider({
  name: 'ResidentPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new ResidentPhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useResidentPhoenixApi,
  ResidentPhoenixApiProvider,
  ApiContext as ResidentPhoenixApiContext,
};

const IMPORT_FAMILY_MEMBERS_KEY = 'import-family-members-key';
const UPDATE_RESIDENT_KEY = 'update-resident-key';

export const useCreateResidentPhoenix = (): UseMutationResult<
  CreateResidentResult,
  unknown,
  CreateResidentParams
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<CreateResidentResult, unknown, CreateResidentParams>(
    'CREATE_RESIDENT_PHOENIX',
    (data) => api.createResident(data),
    {
      onSuccess: (_data, variables) => {
        return invalidateResidentsKeys(
          queryClient,
          variables.body.nursing_home_id
        );
      },
    }
  );
};

export const useImportFamilyMembersPhoenix = (): UseMutationResult<
  ImportFamilyMemberResponsePhoenix,
  unknown,
  ImportFamilyMemberPhoenix
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    ImportFamilyMemberResponsePhoenix,
    unknown,
    ImportFamilyMemberPhoenix
  >(
    IMPORT_FAMILY_MEMBERS_KEY,
    (data) => {
      return api.importFamilyMembers({ body: data });
    },
    {
      onSuccess: async (_, variables) => {
        await invalidateResidentsKeys(queryClient, variables.nursing_home_id);
      },
    }
  );
};

export const useAddNewFamilyMemberPhoenix = (
  residentId: string
): UseMutationResult<
  {
    status: 'Created';
  },
  unknown,
  FamilyMemberPhoenix
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();

  return useMutation<
    {
      status: 'Created';
    },
    unknown,
    FamilyMemberPhoenix
  >(
    UPDATE_RESIDENT_KEY,
    (data) =>
      api.addNewFamilyMember({
        params: { residentId },
        body: {
          data,
        },
      }),
    {
      onSuccess: async () => {
        const resident = await queryClient.fetchQuery<Resident | undefined>(
          getResidentKey(residentId)
        );

        return await Promise.all([
          queryClient.invalidateQueries(PAYMENT_STATUS_KEY(residentId)),
          queryClient.invalidateQueries(PAYMENT_INFO_KEY(residentId)),
          invalidateResident(
            queryClient,
            residentId,
            resident?.nursingHome?.id
          ),
        ]);
      },
    }
  );
};

export const useResendFamilyMemberWelcomeEmail = (): UseMutationResult<
  void,
  unknown,
  {
    residentId: string;
    familyMemberId: string;
  }
> => {
  const api = useResidentPhoenixApi();
  const result = useMutation<
    void,
    unknown,
    {
      residentId: string;
      familyMemberId: string;
    }
  >('RESEND_FAMILY_MEMBER_WELCOME_EMAIL', (data) =>
    api.resendFamilyMemberWelcomeEmail({
      params: data,
    })
  );
  return result;
};

export const useDeleteFamilyMemberForGivenResidentPhoenix =
  (): UseMutationResult<
    {
      success: boolean;
    },
    unknown,
    {
      residentId: string;
      familyMemberId: string;
    }
  > => {
    const api = useResidentPhoenixApi();
    const queryClient = useQueryClient();
    return useMutation<
      {
        success: boolean;
      },
      unknown,
      {
        residentId: string;
        familyMemberId: string;
      }
    >(
      UPDATE_RESIDENT_KEY,
      (data) =>
        api.deleteFamilyMemberForGivenResidentPhoenix({
          params: {
            familyMemberId: data.familyMemberId,
            residentId: data.residentId,
          },
        }),
      {
        onSuccess: async (data, variables) => {
          const resident = await queryClient.fetchQuery<Resident | undefined>(
            getResidentKey(variables.residentId)
          );

          return await Promise.all([
            queryClient.invalidateQueries(
              PAYMENT_STATUS_KEY(variables.residentId)
            ),
            queryClient.invalidateQueries(
              PAYMENT_INFO_KEY(variables.residentId)
            ),
            invalidateResident(
              queryClient,
              variables.residentId,
              resident?.nursingHome?.id
            ),
          ]);
        },
      }
    );
  };
