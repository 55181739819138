export enum MobileOS {
  Android = 'Android',
  iOS = 'iOS',
  iPad = 'iPad',
  Other = 'Other',
}

// eslint-disable-next-line complexity
export const getMobileOS = (): MobileOS => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return MobileOS.Android;
  } else if (
    /iPad/i.test(ua) ||
    (navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1 &&
      // @ts-expect-error // standalone only supported on safari
      navigator.standalone === false)
  ) {
    return MobileOS.iPad;
  } else if (
    /iPhone|iPod/.test(ua) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) {
    return MobileOS.iOS;
  }
  return MobileOS.Other;
};

export const getMobileOSWithSizeHeuristic = (): MobileOS => {
  const mobileOs = getMobileOS();
  if (mobileOs === MobileOS.iOS || mobileOs === MobileOS.Android) {
    // If the device is a phone, we additionally check the screen size
    if (getIsBiggerEqualThanTabletBasedOnDiagonale()) {
      return MobileOS.Other;
    }
  }

  return mobileOs;
};

// eslint-disable-next-line complexity
export const getIsBiggerEqualThanTabletBasedOnDiagonale = (): boolean => {
  const dpi = window.devicePixelRatio * 96; // dpi estimation
  const widthInches = window.screen.width / dpi;
  const heightInches = window.screen.height / dpi;

  const diagonalInches = Math.sqrt(
    Math.pow(widthInches, 2) + Math.pow(heightInches, 2)
  );

  if (diagonalInches < 4.7) {
    return false;
  } else if (diagonalInches >= 4.7 && diagonalInches < 7.1) {
    return false;
  } else if (diagonalInches >= 7.1 && diagonalInches < 13.1) {
    return true;
  } else if (diagonalInches >= 13.1) {
    return true;
  }

  return false;
};
